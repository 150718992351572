

































import Vue from "vue";
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { saveAs } from "file-saver";
import { ref, getBlob } from "firebase/storage";
import { storage } from "@/plugins/firebase";

import { forgetPassword } from "@/utils/backend";
import AuthLayout from "@/components/auth/Layout.vue";
import AuthLink from "@/components/auth/Link.vue";

export default Vue.extend({
  components: {
    AuthLayout,
    AuthLink,
  },
  name: "ForgetPassword",
  data: () => {
    return {
      email: "",
      processing: false,
    };
  },
  methods: {
    ...mapActions(["displayMessage", "showLoading", "hideLoading"]),
    async forget() {
      if (this.processing) return;

      await (this as any).$recaptchaLoaded();

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const token = await (this as any).$recaptcha();

      try {
        this.processing = true;

        await forgetPassword(this.email, token);

        this.displayMessage(
          "An email has been sent to your email address, please follow the instructions to recover the password"
        );

        this.processing = false;
        this.hideLoading();

        this.$router.push({
          name: "Home",
        });
      } catch (err) {
        console.error(err)

        const code = (err as any)?.error?.code;

        if (code === 'OTP_ALREADY_EXIST') {
          this.displayMessage(
            "We have already sent you an email with instructions to reset your password. Please check your inbox. If you haven't received the email within an hour, please check your junk mail folder. If that did not help, please contact us to follow up."
          );
        } else if (code === 'USER_NOT_FOUND') {
          this.processing = false;
          this.displayMessage(
            "We cannot find your record in our system. Please help fill in the Membership Update Form and send back to us. We will process your request."
          );
          try {
            const gsReference = ref(
              storage,
              "gs://lscoba-dev.appspot.com/forms/member_update_form.pdf"
            );
            const bolb = await getBlob(gsReference);

            const fileURL = window.URL.createObjectURL(bolb);
            const tab = window.open();
            tab!.location.href = fileURL;
          } catch (err) {
            console.error(err);
          }
        } else {
          this.displayMessage(
            "Something went wrong. Please help contact us to follow up."
          );
        }
      } finally {
        this.processing = false;
      }
    },
    getErrors(name: string, model: any): Array<string> {
      const errors = new Array<string>();
      if (!model.$dirty) return errors;

      switch (name) {
        case "email":
          !model.email && errors.push("Must be valid email");
          !model.required && errors.push("Email is required");
          break;
        default:
          break;
      }
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email,
    },
  },
  watch: {
    processing: function (val) {
      val ? this.showLoading() : this.hideLoading();
    },
  },
});
